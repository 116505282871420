import { Routes } from '@angular/router';

import { authGuard } from './modules/auth/auth.guard';
import { MainComponent } from './layout/main/main.component';
import { WachtlijstComponent } from './modules/aanmeldingen/pages/wachtlijst/wachtlijst.component';
import { BaseComponent } from './modules/auth/pages/base/base.component';
import { AanmeldingComponent } from './modules/aanmeldingen/pages/aanmelding/aanmelding.component';

// https://www.techiediaries.com/login-signup-api-angular-17/

export const routes: Routes = [
    {
        path: 'auth',
        component: BaseComponent,
        children: [
            {
                path: 'login',
                loadComponent: () => import('./modules/auth/pages/login/login.component').then((m) => m.LoginComponent)
            },
            {
                path: 'password-reset',
                loadComponent: () => import('./modules/auth/pages/password-reset/password-reset.component').then((m) => m.PasswordResetComponent)
            },
            {
                path: 'password-reset-create',
                loadComponent: () => import('./modules/auth/pages/password-reset-create/password-reset-create.component').then((m) => m.PasswordResetCreateComponent)
            }
        ]
    },
    {
        path: 'aanmeldingen',
        component: MainComponent,
        children: [{
            path: 'wachtlijst',
            component: WachtlijstComponent
        }, {
            path: 'aanmelding',
            component: AanmeldingComponent
        }],
        canActivate: [authGuard]
    },
    {
        path: 'home',
        redirectTo: '/aanmeldingen/wachtlijst',
        pathMatch: 'full'
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    {
        path: 'password-reset',
        redirectTo: '/auth/password-reset',
        pathMatch: 'full'
    },
    {
        path: "**",
        loadComponent: () => import('./pages/error/error.component').then((m) => m.ErrorComponent),
        data: {
            code: 404
        }
    }
];
