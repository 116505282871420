import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-form-control-errors',
  standalone: true,
  imports: [CommonModule, TagModule],
  templateUrl: './form-control-errors.component.html',
  styleUrl: './form-control-errors.component.css'
})
export class FormControlErrorsComponent {
  @Input({ required: true }) control!: FormControl;
  @Input() errorMessages: { [key: string]: string } = {};

  protected get errors(): string[] {
    if (this.control.errors !== null && this.control.errors !== undefined) {
      return Object.keys(this.control.errors)
    } else {
      return []
    }
  }
}
