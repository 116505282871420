import { Injectable } from '@angular/core';
import { AuthService } from './services/auth.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private messageService: MessageService
    ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler) {

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authorizedRequest = request.clone({
      //headers: req.headers.set('Cookie', authToken || ""),
      withCredentials: true
    });

    if (request.url.startsWith(`${environment.apiUrl}/system/authentication/`))
        return next.handle(authorizedRequest)

    // send cloned request with header to the next handler.
    return next.handle(authorizedRequest).pipe(tap({
        error: (err) => {
            switch (err.status) {
                case 401:
                    this.messageService.add({
                        closable: true,
                        severity: 'warning',
                        summary: 'Sessie verlopen',
                        detail: 'Je eerdere sessie is verlopen, gelieve opnieuw in te loggen.',
                    })
                    this.auth.logout();
                    break;
                case 403:
                    this.messageService.add({
                        closable: true,
                        severity: 'error',
                        summary: 'Onbevoegd',
                        detail: 'Je hebt niet de machtiging om de gevraagde actie uit te voeren.',
                    })
                    break;
                // default:
                //     throw err
            }
        }
    }));
  }
}