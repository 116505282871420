import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { Response } from '../../system/models/api';
import { AanmeldingCreate, AanmeldingRead, Activiteit, Opvolging, isOpvolging } from '../models/aanmelding';

@Injectable({
  providedIn: 'root'
})
export class AanmeldingService {
  httpClient = inject(HttpClient);
  baseUrl: string

  constructor() {
    this.baseUrl = environment.apiUrl
  }

  public create(aanmelding: AanmeldingCreate): Observable<AanmeldingRead> {
    return this.httpClient.post<Response<AanmeldingRead>>(`${this.baseUrl}/aanmeldingen/aanmeldingen/create`, aanmelding)
      .pipe(map(response => {
        return response.result!
      }))
  }

  public listAll(): Observable<AanmeldingRead[]> {
    return this.httpClient.get<Response<AanmeldingRead[]>>(`${this.baseUrl}/aanmeldingen/aanmeldingen/read`)
      .pipe(map(response => {
        return response.result!;
      }));
  }

  public getOne(id: bigint): Observable<AanmeldingRead> {
    return this.httpClient.get<Response<AanmeldingRead>>(`${this.baseUrl}/aanmeldingen/aanmeldingen/read?id=${id}`)
      .pipe(map(response => response.result!));
  }

  public updateStatus(aanmelding: AanmeldingRead, status: string): Observable<Response<AanmeldingRead>> {
    return this.httpClient.post<Response<AanmeldingRead>>(`${this.baseUrl}/aanmeldingen/aanmeldingen/update-status`, {
      aanmelding: aanmelding.id,
      nieuweStatus: status
    });
  }

  public getActiviteiten(aanmelding: AanmeldingRead): Observable<Activiteit[]> {
    return this.httpClient.get<Opvolging[]>(`${this.baseUrl}/aanmeldingen/activiteit/read?aanmeldingId=${aanmelding.id}`);
  }

  public getOpvolgingen(aanmelding: AanmeldingRead): Observable<Opvolging[]> {
    return this.getActiviteiten(aanmelding)
      .pipe(map(activiteiten => activiteiten.filter(isOpvolging)));
  }
}
