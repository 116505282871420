import { User } from "../../system/models/user"

export enum Status {
    WACHTLIJST = "wachtlijst",
    IN_BEHANDELING = "in_behandeling",
    INTAKE_GEPLAND = "intake_gepland",
    INTERN_PSYCHOLOOG = "intern_psycholoog",
    INTERN_PSYCHOLOOG_PSYCHIATER = "intern_psycholoog_psychiater",
    INTERN_PSYCHIATER = "intern_psychiater",
    OPGESTART = "opgestart",
    EXTERN = "extern",
    AFGEROND = "afgerond"
}

export interface AanmeldingCreate {
    id: number

    kind_rrnr?: string
    kind_voornaam: string
    kind_achternaam: string
    kind_geboortedatum: string
    kind_adres_straat?: string
    kind_adres_huisnummer?: string
    kind_adres_postcode?: string
    kind_adres_woonplaats?: string

    ouder_voornaam: string
    ouder_achternaam: string
    ouder_email: string
    ouder_telefoon?: string
    
    aanmelder_relatie: string
    aanmelder_voornaam?: string
    aanmelder_achternaam?: string
    aanmelder_email?: string
    aanmelder_telefoon?: string
    
    problematiek: string

    user?: User
}

export interface AanmeldingRead {
    id: bigint
    status: Status
    toegewezen_aan: string[]
    
    tijdstip_aanmelding: Date

    kind_rrnr?: string
    kind_voornaam: string
    kind_achternaam: string
    kind_geboortedatum: Date
    kind_adres_straat: string
    kind_adres_huisnummer: string
    kind_adres_postcode: string
    kind_adres_woonplaats: string

    ouder_voornaam: string
    ouder_achternaam: string
    ouder_email: string
    ouder_telefoon: string
    
    aanmelder_relatie: string
    aanmelder_voornaam?: string
    aanmelder_achternaam?: string
    aanmelder_email?: string
    aanmelder_telefoon?: string
    
    problematiek: string
}

export interface Activiteit {
    [x: string]: unknown
    id: bigint
    uuid: string

    timestap: Date

    aanmeldingId: bigint
    user: User[]
}

export interface Opvolging extends Activiteit {
    type: "opvolging"
    status: Status
}
export const isOpvolging = (value: Activiteit): value is Opvolging => (value as Opvolging).type == 'opvolging';