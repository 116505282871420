import { Component } from '@angular/core';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { SpeedDialModule } from 'primeng/speeddial';
import { ToolbarModule } from 'primeng/toolbar';

import { AuthService } from '../../modules/auth/services/auth.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [AvatarModule, ButtonModule, SpeedDialModule, ToolbarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  speedDialItems: MenuItem[]
  initials: string

  constructor(authService: AuthService) {
    this.speedDialItems = [
      {
        icon: 'pi pi-sign-out',
        tooltipOptions: {
          tooltipLabel:'Uitloggen',
          tooltipPosition: 'bottom'
        },
        command: () => {
          console.log('Clicked logout');
          authService.logout();
        }
      },
      // {
      //   icon: 'pi pi-unlock',
      //   tooltipOptions: {
      //     tooltipLabel: 'Reset code aanmaken',
      //     tooltipPosition: 'bottom'
      //   },
      //   routerLink: '/auth/password-reset-create'
      // }
    ]
    const user = authService.getCurrentUser()
    this.initials = user.firstName.charAt(0) + user.lastName.charAt(0)
  }
}
