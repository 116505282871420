import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule} from 'primeng/timeline'
import { ToolbarModule } from 'primeng/toolbar';

import { TableRowExpandEvent } from 'primeng/table';

import { AanmeldingRead, Opvolging, Status } from '../../models/aanmelding';
import { AanmeldingService } from '../../services/aanmelding.service';
import { AuthService } from '../../../auth/services/auth.service';
import { Subject } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';


@Component({
  selector: 'app-wachtlijst',
  standalone: true,
  imports: [CommonModule, ButtonModule, DividerModule, SkeletonModule, SplitButtonModule, TableModule, TimelineModule, ToolbarModule, TooltipModule],
  templateUrl: './wachtlijst.component.html',
  styleUrl: './wachtlijst.component.css'
})
export class WachtlijstComponent implements OnInit {
  expandedRows = {};
  aanmeldingen: AanmeldingRead[] = [];
  filteredAanmeldingen: AanmeldingRead[] = [];

  selectedStatus: string = Status.WACHTLIJST;

  interne_statussen: MenuItem[] = [
    {
      label: "Psychiater",
      command: () => this.selectStatus(Status.INTERN_PSYCHIATER)
    }, {
      label: "Psycholoog",
      command: () => this.selectStatus(Status.INTERN_PSYCHOLOOG)
    }, {
      label: "Psycholoog + Psychiater",
      command: () => this.selectStatus(Status.INTERN_PSYCHOLOOG_PSYCHIATER),
    }, 
  ];

  _aanmelding?: AanmeldingRead;
  doorverwijzingen: MenuItem[] = [
    {
      label: "Intern: Psychiater",
      command: () => {
        if (this._aanmelding)
          this.updateStatus(this._aanmelding, Status.INTERN_PSYCHIATER);
      }
    }, {
      label: "Intern: Psycholoog",
      command: () => {
        if (this._aanmelding)
          this.updateStatus(this._aanmelding, Status.INTERN_PSYCHOLOOG);
      }
    }, {
      label: "Intern: Psycholoog + Psychiater",
      command: () => {
        if (this._aanmelding)
          this.updateStatus(this._aanmelding, Status.INTERN_PSYCHOLOOG_PSYCHIATER);
      }
    }, {
      label: "Extern",
      command: () => {
        if (this._aanmelding)
          this.updateStatus(this._aanmelding, Status.EXTERN);
      }
    },
  ];

  opvolgingen: Map<AanmeldingRead, Subject<Opvolging[]>> = new Map<AanmeldingRead, Subject<Opvolging[]>>()

  mask: boolean = true;

  constructor(
    protected aanmeldingService: AanmeldingService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    const uid = authService.getCurrentUser().id;
    this.mask = uid < 2 || uid > 7;
    
  }

  ngOnInit() {
    this.aanmeldingService.listAll()
      .subscribe(aanmeldingen => {
        for (const aanmelding of aanmeldingen) {
          const subject = new Subject<Opvolging[]>();
          subject.next([]);
          this.opvolgingen.set(aanmelding, subject);
        }
        this.aanmeldingen = aanmeldingen;
        this.doFilter();
      })
  }

  private doFilter() {
    this.filteredAanmeldingen = this.aanmeldingen.filter(aanmelding => {
      return aanmelding.status == this.selectedStatus;
    });
  }

  getSeverity(status: string): "success" | undefined {
    if (this.selectedStatus.startsWith(status)) {
      return "success";
    } else {
      return undefined;
    }
  }

  selectStatus(status: string | MouseEvent) {
    if (typeof status == "string") {
      this.selectedStatus = status;
    }
    this.doFilter();
  }

  selectAanmelding(aanmelding: AanmeldingRead) {
    this._aanmelding = aanmelding;
  }

  getStatus(key: string): Status | undefined {
    for (const status of Object.values(Status)) {
      if (status.toString() == key) {
        return status;
      }
    }
    return undefined;
  }

  updateStatus(aanmelding: AanmeldingRead, status: string) {
    this.aanmeldingService.updateStatus(aanmelding, status)
      .subscribe(response => {
        if (response.success) {
          const s: Status = this.getStatus(status)!;
          this.messageService.add({
            closable: true,
            severity: 'success',
            summary: 'Status gewijzigd',
            detail: "De status van " + aanmelding.kind_voornaam + " " + aanmelding.kind_achternaam + " werd gewijzigd naar " + s
          });
          aanmelding.status = s;
          this.doFilter();
        } else {
          this.messageService.add({
            closable: true,
            severity: 'error',
            summary: 'Status kon niet gewijzigd worden',
            detail: response.message
          })
        }
      });
  }

  getOpvolgingen(aanmelding: AanmeldingRead): Subject<Opvolging[]> {
    return this.opvolgingen.get(aanmelding)!;
  }

  onRowExpand(event: TableRowExpandEvent) {
    const aanmelding = event.data as AanmeldingRead;
    console.log(aanmelding)
    // let opvolgingen_ = this.opvolgingen.get(aanmelding)!
    // this.aanmeldingService.getOpvolgingen(aanmelding)
    //   .subscribe(opvolgingen => opvolgingen_.next(opvolgingen))
  }
}
