<p-toolbar styleClass="loge-bg-green" [style]="{'border': '0', 'border-radius': '0', 'padding': '0'}">
   <div class="p-toolbar-group-start">
     <img id="logo" src="assets/logo/logo-square-xs.png" alt="LOGO">
     <span id="logo_title">
       <a href="/">truweel</a>
     </span>
   </div>
   <div class="p-toolbar-group-end">
     <p-speedDial
        direction="left" [model]="speedDialItems" rotateAnimation="false"
        [buttonStyle]="{'width':'50px','height':'50px','border-color': 'var(--truweel-yellow)','border-width':'3px'}">
      <ng-template pTemplate="button">
        <p-avatar size="xlarge" [label]="initials" shape="circle" [style]="{'font-size':'1.5rem', 'background-color': 'var(--truweel-yellow-300)'}" />
      </ng-template>
     </p-speedDial>
   </div>
 </p-toolbar>
   