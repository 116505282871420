<p-toolbar>
    <div class="p-toolbar-group-start">
        <span>Filters:&nbsp;</span>
        <p-button label="Wachtlijst" size="small" [severity]="getSeverity('wachtlijst')" (onClick)="selectStatus('wachtlijst')" />
        <p-button label="In behandeling" size="small" [severity]="getSeverity('in_behandeling')" (onClick)="selectStatus('in_behandeling')" />
        <p-button label="Intake gepland" size="small" [severity]="getSeverity('intake_gepland')" (onClick)="selectStatus('intake_gepland')" />
        <p-splitButton label="Interne doorverwijzing" size="small" icon="pi pi-check" [severity]="getSeverity('intern')" [model]="interne_statussen" />
        <p-button label="Opgestart" size="small" [severity]="getSeverity('opgestart')" (onClick)="selectStatus('opgestart')" />
        <p-button label="Externe doorverwijzing" size="small" [severity]="getSeverity('extern')" (onClick)="selectStatus('extern')" />
        <p-button label="Afgerond" size="small" [severity]="getSeverity('afgerond')" (onClick)="selectStatus('afgerond')" />
    </div>
    <div class="p-toolbar-group-end">
        <p-button label="Aanmelden" size="small" severity="secondary" routerLink="../aanmelding" />
    </div>
</p-toolbar>

<p-table [value]="filteredAanmeldingen" dataKey="id" [tableStyle]="{ 'min-width': '60rem' }" [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th pSortableColumn="tijdstip_aanmelding">Tijdstip aanmelding <p-sortIcon field="tijdstip_aanmelding" /></th>
            <th>Voornaam</th>
            <th>Achternaam</th>
            <th pSortableColumn="kind_geboortedatum">Geboortedatum <p-sortIcon field="kind_geboortedatum" /></th>
            <th>Adres</th>
            <!-- <th>Toegewezen aan</th> -->
            <th>Status</th>
            <!-- <th>Detail</th> -->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-aanmelding let-expanded="expanded">
        <tr *ngIf="mask; else elseBlock">
            <td>
                <p-button size="small" type="button" pRipple [pRowToggler]="aanmelding" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
            </td>
            <td>
                {{ aanmelding.tijdstip_aanmelding }}
                <i *ngIf="aanmelding.user !== undefined" [pTooltip]="'Intern aangemeld door ' + aanmelding.user.firstName + ' ' + aanmelding.user.lastName" class="pi pi-info-circle" style="color: var(--primary-color)"></i>
            </td>
            <td><p-skeleton /></td>
            <td><p-skeleton /></td>
            <td>{{ aanmelding.kind_geboortedatum }}</td>
            <td><p-skeleton /></td>
            <!-- <td>{{ aanmelding.toegewezen_aan }}</td> -->
            <td>
                {{ aanmelding.status }}<br />
                <p-button size="small" *ngIf="aanmelding.status === 'wachtlijst'" label="In behandeling nemen" (onClick)="updateStatus(aanmelding, 'in_behandeling')" />
                <p-button size="small" *ngIf="aanmelding.status === 'in_behandeling'" label="Intake plannen" (onClick)="updateStatus(aanmelding, 'intake_gepland')" />
                <p-splitButton size="small" *ngIf="aanmelding.status === 'intake_gepland'" label="Doorverwijzen" [model]="doorverwijzingen" (onClick)="selectAanmelding(aanmelding)" (onDropdownClick)="selectAanmelding(aanmelding)" />
                <p-button size="small" *ngIf="aanmelding.status.startsWith('intern')" label="Opstarten" (onClick)="updateStatus(aanmelding, 'opgestart')" />
                <p-button size="small" *ngIf="aanmelding.status === 'opgestart'" label="Afronden" (onClick)="updateStatus(aanmelding, 'afgerond')" />
                <p-button size="small" *ngIf="aanmelding.status === 'extern'" label="Afronden" (onClick)="updateStatus(aanmelding, 'afgerond')" />
            </td>
            <!-- <td><p-button label="Test" /></td> -->
        </tr>
        <ng-template  #elseBlock>
        <tr>
            <td>
                <p-button size="small" type="button" pRipple [pRowToggler]="aanmelding" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
            </td>
            <td>{{ aanmelding.tijdstip_aanmelding }}</td>
            <td>{{ aanmelding.kind_voornaam }}</td>
            <td>{{ aanmelding.kind_achternaam }}</td>
            <td>{{ aanmelding.kind_geboortedatum }}</td>
            <td>
                {{ aanmelding.kind_adres_straat }}&nbsp;{{ aanmelding.kind_adres_huisnummer}}<br />
                {{ aanmelding.kind_adres_postcode }}&nbsp;{{ aanmelding.kind_adres_woonplaats}}<br />
            </td>
            <!-- <td>{{ aanmelding.toegewezen_aan }}</td> -->
            <td>
                {{ aanmelding.status }}<br />
                <p-button size="small" *ngIf="aanmelding.status === 'wachtlijst'" label="In behandeling nemen" (onClick)="updateStatus(aanmelding, 'in_behandeling')" />
                <p-button size="small" *ngIf="aanmelding.status === 'in_behandeling'" label="Intake plannen" (onClick)="updateStatus(aanmelding, 'intake_gepland')" />
                <p-splitButton size="small" *ngIf="aanmelding.status === 'intake_gepland'" label="Doorverwijzen" [model]="doorverwijzingen" (onClick)="selectAanmelding(aanmelding)" (onDropdownClick)="selectAanmelding(aanmelding)" />
                <p-button size="small" *ngIf="aanmelding.status.startsWith('intern')" label="Opstarten" (onClick)="updateStatus(aanmelding, 'opgestart')" />
                <p-button size="small" *ngIf="aanmelding.status === 'opgestart'" label="Afronden" (onClick)="updateStatus(aanmelding, 'afgerond')" />
                <p-button size="small" *ngIf="aanmelding.status === 'extern'" label="Afronden" (onClick)="updateStatus(aanmelding, 'afgerond')" />
            </td>
            <!-- <td><p-button label="Test" /></td> -->
        </tr>
        </ng-template>
        <tr>
            <p-timeline [value]="(getOpvolgingen(aanmelding)|async)!" layout="horizontal" align="bottom">
                <ng-template pTemplate="content" let-event>
                    {{ event }}
                </ng-template>
            </p-timeline>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-aanmelding>
        <tr>
            <td></td>
            <td colspan="8" *ngIf="mask; else elseBlock">
                <h4>Rijksregisternummer</h4> <p>{{aanmelding.kind_rrnr}}</p>
                <p-divider />
                <div class="row">
                    <div>
                        <h4>Ouder</h4>
                        <p-skeleton />
                    </div>
                    <ng-template [ngIf]="aanmelding.aanmelder_relatie !== 'ouder' && aanmelding.aanmelder_relatie !== 'voogd'">
                        <p-divider layout="vertical" />
                        <div>
                            <h4>Aanmelder ({{ aanmelding.aanmelder_relatie }})</h4>
                            <p-skeleton />
                        </div>
                    </ng-template>
                </div>
                <p-divider />
                <h4>Problematiek</h4> <p><p-skeleton /></p>
            </td>
            <ng-template #elseBlock>
                <td colspan="8">
                    <h4>Rijksregisternummer</h4> <p>{{aanmelding.kind_rrnr}}</p>
                    <p-divider />
                    <div class="row">
                        <div>
                            <h4>Ouder</h4>
                            <p>{{ aanmelding.ouder_voornaam }} {{ aanmelding.ouder_achternaam }}</p>
                            <p>{{ aanmelding.ouder_email }}</p>
                            <p>{{ aanmelding.ouder_telefoon }}</p>
                        </div>
                        <ng-template [ngIf]="aanmelding.aanmelder_relatie !== 'ouder' && aanmelding.aanmelder_relatie !== 'voogd'">
                            <p-divider layout="vertical" />
                            <div>
                                <h4>Aanmelder ({{ aanmelding.aanmelder_relatie }})</h4>
                                <p>{{ aanmelding.aanmelder_voornaam }} {{ aanmelding.aanmelder_achternaam }}</p>
                                <p>{{ aanmelding.aanmelder_email }}</p>
                                <p>{{ aanmelding.aanmelder_telefoon }}</p>
                            </div>
                        </ng-template>
                    </div>
                    <p-divider />
                    <h4>Problematiek</h4> <p>{{ aanmelding.problematiek }}</p>
                </td>
            </ng-template>
        </tr>
    </ng-template>
</p-table>
