import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RadioButtonModule } from 'primeng/radiobutton';

import { FormControlErrorsComponent } from '../../../../components/form-control-errors/form-control-errors.component';
import { AanmeldingService } from '../../services/aanmelding.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aanmelding',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, CalendarModule, DropdownModule, FieldsetModule, InputMaskModule, InputTextareaModule, InputTextModule, KeyFilterModule, RadioButtonModule, FormControlErrorsComponent],
  templateUrl: './aanmelding.component.html',
  styleUrl: './aanmelding.component.css'
})
export class AanmeldingComponent {
  protected aanmelder_options = [{
    label: "Ouder",
    value: "ouder"
  }, {
    label: "Voogd",
    value: "voogd"
  }, {
    label: "Intern",
    value: "intern"
  }, {
    label: "Andere",
    value: "andere"
  }]

  protected form = new FormGroup({
    // Kind
    kind_voornaam: new FormControl('', [Validators.required]),
    kind_achternaam: new FormControl('', [Validators.required]),
    kind_rijksregisternummer: new FormControl('', [this.rijksregisternummerValidator(), this.geboortedatumAndRijksregisternummerValidator()]),
    kind_geboortedatum: new FormControl<Date | null>(null, [Validators.required, this.geboortedatumAndRijksregisternummerValidator()]),
    kind_adres_straat: new FormControl('', []),
    kind_adres_huisnummer: new FormControl('', []),
    kind_adres_postcode: new FormControl('', []),
    kind_adres_woonplaats: new FormControl('', []),
    // Ouder
    ouder_voornaam: new FormControl('', [Validators.required]),
    ouder_achternaam: new FormControl('', [Validators.required]),
    ouder_email: new FormControl('', [Validators.required, Validators.email]),
    ouder_telefoon: new FormControl('', []),
    // Aanmelder
    aanmelder_relatie: new FormControl('', [Validators.required]),
    aanmelder_andere_relatie: new FormControl('', []),
    aanmelder_voornaam: new FormControl('', []),
    aanmelder_achternaam: new FormControl('', []),
    aanmelder_email: new FormControl('', [Validators.email]),
    aanmelder_telefoon: new FormControl('', []),
    // Problematiek
    problematiek: new FormControl('', [Validators.required]),
  }, {
    //validators: [this.rijksregisternummerValidator()]
  })

  protected errorMessages = {
    required: 'Dit is een verplicht veld',
    ongeldig_rrnr: "Ongeldig rijksregisternummer",
    overeenkomst: "Rijksregisternummer komt niet overeen met geboortedatum."
  }

  constructor(
    private aanmeldingService: AanmeldingService,
    private messageService: MessageService,
    private router: Router
  ) {
  }

  onSubmit() {
    if (this.form.valid) {
      const geboortedatum = this.form.value.kind_geboortedatum!

      this.aanmeldingService.create({
        id: Math.floor(Math.random() * 2^10),

        kind_rrnr: this.form.value.kind_rijksregisternummer || undefined,
        kind_voornaam: this.form.value.kind_voornaam!,
        kind_achternaam: this.form.value.kind_achternaam!,
        kind_geboortedatum: `${geboortedatum.getFullYear}-${zeropad(geboortedatum.getMonth()+1)}-${zeropad(geboortedatum.getDate())}`,
        kind_adres_straat: this.form.value.kind_adres_straat || undefined,
        kind_adres_huisnummer: this.form.value.kind_adres_huisnummer || undefined,
        kind_adres_postcode: this.form.value.kind_adres_postcode || undefined,
        kind_adres_woonplaats: this.form.value.kind_adres_woonplaats || undefined,

        ouder_voornaam: this.form.value.ouder_voornaam!,
        ouder_achternaam: this.form.value.ouder_achternaam!,
        ouder_email: this.form.value.ouder_email!,
        ouder_telefoon: this.form.value.ouder_telefoon || undefined,

        aanmelder_relatie: this.form.value.aanmelder_relatie!,

        aanmelder_voornaam: this.form.value.aanmelder_voornaam || undefined,
        aanmelder_achternaam: this.form.value.aanmelder_achternaam || undefined,
        aanmelder_email: this.form.value.aanmelder_email || undefined,
        aanmelder_telefoon: this.form.value.aanmelder_telefoon || undefined,

        problematiek: this.form.value.problematiek!
      }).subscribe({
        next: aanmelding => {
          this.router.navigate(["/aanmeldingen/wachtlijst"]).then(
            () => {
              this.messageService.add({
                closable: true,
                severity: 'success',
                summary: 'Aanmelding gelukt',
                detail: `${aanmelding.kind_voornaam} ${aanmelding.kind_achternaam} werd succesvol aangemeld.`
              })
            }
          )
        },
        error: err => {
          const invalid_fields = err.error.message["Invalid fields"] as {[index: string]: string}
          const messages: string[] = []
          for (const field in invalid_fields)
            messages.push(invalid_fields[field])
          this.messageService.add({
            closable: true,
            severity: 'error',
            summary: 'Aanmelding mislukt',
            detail: `De aanmelding kon niet worden uitgevoerd: ${messages.join(', ')}`
          })
        }
      })
    }
  }

  private trimRijksregisternummer(rijksregisternummer: string): string {
    return rijksregisternummer ? rijksregisternummer.replaceAll(/\s|_/g, '') : ""
  }

  private rijksregisternummerBefore2000(rijksregisternummer: string): boolean | null {
    const match = rijksregisternummer.match(/^(\d{6})(\d{3})(\d{2})/)
    if (!match) return null

    const base_string = match[1] + match[2]
    const modulo_1900 = Number(BigInt(base_string) % BigInt(97))
    const modulo_2000 = Number(BigInt('2' + base_string) % BigInt(97))
    const checksum = Number(match[3])

    if (modulo_1900 == 97 - checksum)
      return true
    else if (modulo_2000 == 97 - checksum)
      return false
    else
      return null
  }

  private rijksregisternummerValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const rijksregisternummer = this.trimRijksregisternummer(control.value)
      if (rijksregisternummer.length == 0) return null
      const before_2000 = this.rijksregisternummerBefore2000(rijksregisternummer)
      if (before_2000 === null) {
        return {
          ongeldig_rrnr: true
        }
      } else {
        return null
      }
    }
  }

  private geboortedatumAndRijksregisternummerValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate: Date = control.parent?.get("kind_geboortedatum")!.value
      const rijksregisternummer = this.trimRijksregisternummer(control.parent?.get("kind_rijksregisternummer")!.value)
      if (!birthDate || !rijksregisternummer) return null
      
      const before_2000 = this.rijksregisternummerBefore2000(rijksregisternummer)

      const birthYear = birthDate.getFullYear()
      const birthMonth = birthDate.getMonth() + 1
      const birthDay = birthDate.getDate()
      const yymmdd = `${zeropad(birthYear % 100)}${zeropad(birthMonth)}${zeropad(birthDay)}`

      let match = rijksregisternummer.startsWith(yymmdd)
      if (match && before_2000 !== null) {
        match = (before_2000 && birthYear < 2000) || (!before_2000 && birthYear >= 2000)
      }
      if (!match) {
          return {
            overeenkomst: true
          }
      } else {
        return null
      }
    }
  }
}

function zeropad(n: number): string {
  return n.toString().padStart(2, "0")
}
