<form class="form-group p-3 mb-3" [formGroup]="form" (ngSubmit)="onSubmit()">
    <p-fieldset legend="Gegevens kind" class="m-3">
        <span class="font-bold block mb-2 mt-0"><label for="kind_voornaam">Voornaam</label></span>
        <input type="text" pInputText formControlName="kind_voornaam" />
        <app-form-control-errors class="mt-1" [control]="form.controls['kind_voornaam']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="kind_achternaam">Achternaam</label></span>
        <input type="text" pInputText formControlName="kind_achternaam" >
        <app-form-control-errors class="mt-1" [control]="form.controls['kind_achternaam']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="kind_rijksregisternummer">Rijksregisternummer</label></span>
        <p-inputMask mask="999999 999 99" formControlName="kind_rijksregisternummer" placeholder="999999 999 99" />
        <app-form-control-errors class="mt-1" [control]="form.controls['kind_rijksregisternummer']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="kind_geboortedatum">Geboortedatum</label></span>
        <p-calendar formControlName="kind_geboortedatum" dateFormat="dd/mm/yy" />
        <app-form-control-errors class="mt-1" [control]="form.controls['kind_geboortedatum']" [errorMessages]="errorMessages" />

        <p-fieldset legend="Adres (zoals vermeld op het strookje van het ziekenfonds)" class="mt-2">
            <span class="font-bold block mb-2 mt-0"><label for="kind_adres_straat">Straat</label></span>
            <input type="text" pInputText formControlName="kind_adres_straat" >
            <app-form-control-errors class="mt-1" [control]="form.controls['kind_adres_straat']" [errorMessages]="errorMessages" />

            <span class="font-bold block mb-2 mt-2"><label for="kind_adres_huisnummer">Huisnummer</label></span>
            <input type="text" pInputText formControlName="kind_adres_huisnummer" >
            <app-form-control-errors class="mt-1" [control]="form.controls['kind_adres_huisnummer']" [errorMessages]="errorMessages" />

            <span class="font-bold block mb-2 mt-2"><label for="kind_adres_postcode">Postcode</label></span>
            <input type="text" pInputText formControlName="kind_adres_postcode" >
            <app-form-control-errors class="mt-1" [control]="form.controls['kind_adres_postcode']" [errorMessages]="errorMessages" />

            <span class="font-bold block mb-2 mt-2"><label for="kind_adres_woonplaats">Woonplaats</label></span>
            <input type="text" pInputText formControlName="kind_adres_woonplaats" >
            <app-form-control-errors class="mt-1" [control]="form.controls['kind_adres_woonplaats']" [errorMessages]="errorMessages" />
        </p-fieldset>
    </p-fieldset>
    
    <p-fieldset legend="Gegevens ouder / voogd" class="m-3">
        <span class="font-bold block mb-2 mt-0"><label for="ouder_voornaam">Voornaam</label></span>
        <input type="text" pInputText formControlName="ouder_voornaam" >
        <app-form-control-errors class="mt-1" [control]="form.controls['ouder_voornaam']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="ouder_achternaam">Achternaam</label></span>
        <input type="text" pInputText formControlName="ouder_achternaam" >
        <app-form-control-errors class="mt-1" [control]="form.controls['ouder_achternaam']" [errorMessages]="errorMessages" />
        
        <span class="font-bold block mb-2 mt-2"><label for="ouder_email">E-mailadres</label></span>
        <input type="text" pInputText formControlName="ouder_email" >
        <app-form-control-errors class="mt-1" [control]="form.controls['ouder_email']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="ouder_telefoon">Telefoonnummer</label></span>
        <input type="text" pInputText formControlName="ouder_telefoon" >
        <app-form-control-errors class="mt-1" [control]="form.controls['ouder_telefoon']" [errorMessages]="errorMessages" />
    </p-fieldset>
    
    <p-fieldset legend="Gegevens aanmelder" class="m-3">
        <span class="font-bold block mb-2 mt-0"><label for="aanmelder_relatie">Relatie</label></span>
        <p-dropdown [options]="aanmelder_options" optionLabel="label" class="w-100" formControlName="aanmelder_relatie" />
        <app-form-control-errors class="mt-1" [control]="form.controls['aanmelder_relatie']" [errorMessages]="errorMessages" />
        
        <span class="font-bold block mb-2 mt-2"><label for="aanmelder_voornaam">Voornaam</label></span>
        <input type="text" pInputText formControlName="aanmelder_voornaam" >
        <app-form-control-errors class="mt-1" [control]="form.controls['aanmelder_voornaam']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="aanmelder_achternaam">Achternaam</label></span>
        <input type="text" pInputText formControlName="aanmelder_achternaam" >
        <app-form-control-errors class="mt-1" [control]="form.controls['aanmelder_achternaam']" [errorMessages]="errorMessages" />
        
        <span class="font-bold block mb-2 mt-2"><label for="aanmelder_email">E-mailadres</label></span>
        <input type="text" pInputText formControlName="aanmelder_email" >
        <app-form-control-errors class="mt-1" [control]="form.controls['aanmelder_email']" [errorMessages]="errorMessages" />

        <span class="font-bold block mb-2 mt-2"><label for="aanmelder_telefoon">Telefoonnummer</label></span>
        <input type="text" pInputText formControlName="aanmelder_telefoon" >
        <app-form-control-errors class="mt-1" [control]="form.controls['aanmelder_telefoon']" [errorMessages]="errorMessages" />
    </p-fieldset>

    <p-fieldset legend="Problematiek" class="m-3">
        <textarea pInputText formControlName="problematiek" rows="10" maxlength="65535" class="w-100"></textarea>
        <app-form-control-errors class="mt-1" [control]="form.controls['problematiek']" [errorMessages]="errorMessages" />
    </p-fieldset>

    <p-button class="m-3" label="Opslaan" (onClick)="onSubmit()" [disabled]="!form.valid" />
</form>